<!-- 园区管理-线下售票 -->

<template>
  <div class="oltiket main-cnt" v-if="route.path == '/scenic/sale/olsale'">
    <div class="content">
      <common-table ref="ticketTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
        :apiName="ScenicApi.sellList" :filters="filters" :columns="tableColumns" @saleTickets="saleTickets" @typeChange="typeChange">
      </common-table>
    </div>

    <!-- 售票入口弹框 -->
    <w-dialog ref="ticketDialog" class="ticket-dialog" title="售票信息" cancelText="添加至购物车" width="90%" btnWidth="140px"
      top="4vh" confirmText="确认售票" @wConfirm="confirm" @wCancel="onIncreaseBtn" :cancelEvent="'cancelEvent'"
      @iconClose="wCancel">
      <div class="ticket-content">
        <div class="left-content">
          <div class="basic-info">
            <div class="ticket-title">{{ ticketInfo?.st_name }}</div>
            <div class="basic-item">
              <span>所属园区</span> {{ ticketInfo?.s_name }}
            </div>
            <div class="basic-item">
              <span>售价</span><span style="color: #0072F6;"> ￥{{ ticketInfo?.st_price }}</span>
            </div>
          </div>
          <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top" class="ticket-form">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item prop="num" label="售票数量">
                  <KeyBoard ref="numKeyBoardRef" v-model="formData.num" :placeholder="'请输入售票数量'"  :type="'number'"
                    @onShowKey="numShowKey">
                  </KeyBoard>
                  <!-- <el-input v-model="formData.num" autocomplete="off" placeholder="请输入售票数量" clearable type="number"
                     oninput="if(value < 1) value = 1" /> -->
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item prop="is_register" label="是否激活" style="margin-right: 20px;">
                  <el-select v-model="formData.is_register" placeholder="请选择是否激活" clearable>
                    <el-option label="是" value="2"></el-option>
                    <el-option label="否" value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="8" v-show="formData.is_register == '2'">
                <el-form-item prop="start_time" label="初次使用日期">
                  <el-date-picker v-model="formData.start_time" type="date" placeholder="选择日期"
                    :disabled-date="disabledDate" @change="startTimeChange">
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="8" v-if="ticketType == 2">
                <el-form-item prop="yh_money" label="优惠金额">
                  <KeyBoard ref="yhKeyBoardRef" v-model="formData.yh_money" :placeholder="'请输入优惠金额'"
                    @onShowKey="yhShowKey">
                  </KeyBoard>
                  <!-- <el-input v-model="formData.yh_money" autocomplete="off" placeholder="请输入优惠金额" clearable type="number"
                    min="0" /> -->
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="ticketType == 1">
                <el-form-item prop="" label="代金券抵扣金额">
                  <KeyBoard ref="couponKeyBoardRef" v-model="formData.coupon_money" :placeholder="'请输入代金券抵扣金额'"
                    @onShowKey="couponShowKey" @inputEvent="voucherDeduction">
                  </KeyBoard>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item prop="" label="会员信息">
                  <div class="sx-m-b">
                    <KeyBoard ref="mkeyBoardRef" v-model="searchContent" :type="'number'" :placeholder="'请选择会员'"
                      @inputEvent="memberEvent" @onShowKey="searchShowKey">
                      <template #operate>
                        <div class="member-info">
                          <div v-if="searchMembers.length == 0">
                            <div style="text-align: center;padding: 5px 0;color: var(--text-color);">暂无数据</div>
                            <el-button class="" @click.stop="onRegisterBtn" plain round style="width: 100%;background-color: var(--btn-bg-color);
                            border: 1px solid var(--btn-bg-color);
                            color: var(--text-white-color) !important;">注册会员</el-button>
                          </div>
                          <div v-else v-for="(item, i) in searchMembers" :key="i" class="selected-members"
                            @click="handleSelect(item)">
                            <div>{{item.m_mobile}}</div>
                            <div>{{item.m_realname}}</div>
                          </div>
                        </div>
                      </template>
                    </KeyBoard>
                    <div class="flex justify_between person_content" v-if="allPerson.m_mobile">
                      <span>{{ allPerson.m_mobile }}</span>
                      <span v-if="allPerson.m_realname">{{ allPerson.m_realname }}</span>
                    </div>
                  </div>
                </el-form-item>
              </el-col>

              <el-col :span="8" v-if="ticketInfo.birthday_count > 0" v-for="(item, i) in birthdayCount" :key="i">
                <el-form-item prop="" label="生日日期">
                  <div style="margin-right: 10px;margin-bottom: 10px;">
                    <el-date-picker v-model="item.child_birthday" type="date" placeholder="请选择日期"
                      value-format="YYYY-MM-DD">
                    </el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="total-money">
              合计金额<span>￥{{ totalMoney() }}</span>
            </div>

            <el-form-item prop="payway" label="支付方式">
              <el-radio-group v-model="formData.payway" @change="changeRadio">
                <template v-for="(el, index) in orderTypeOptions" :key="index">
                  <el-radio :label="el.orp_id">{{ el.orp_name }}</el-radio>
                </template>
              </el-radio-group>
            </el-form-item>

            <div class="upload-box" v-if="showUpload && formData.is_register == '2'">
              <el-dropdown>
                <div class="into-face">录入人脸</div>
                <template #dropdown>
                  <el-dropdown-menu class="face-dropdown-menu">
                    <el-dropdown-item class="face-dropdown-item">
                      <imgUpload uploadTitle="人脸照片" uploadText="本地上传" @uploadFile="uploadFile($event)"
                        :noReplaceImg="false" @uploadLoading="uploadLoading"></imgUpload>
                    </el-dropdown-item>
                    <el-dropdown-item @click="openFaceDialog(index)" class="face-dropdown-item">拍照录入
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <div class="img-container flex flex-wrap" v-if="facialPhotos.length > 0">
                <div class="face-img" v-for="(imgs, i) in facialPhotos" :key="i">
                  <el-image style="width: 100px; height: 100px" :src="imgs.standard_url" :preview-src-list="srcList"
                    fit="cover">
                  </el-image>
                  <span class="del" @click="deleteFile(imgs, i)">
                    <el-icon>
                      <Close />
                    </el-icon>
                  </span>
                </div>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </w-dialog>
    <StatusDialog ref="loadingDialog" :type="1" :title="lastPayway ? '等待支付中' : '确认订单中'" :btnWidth="'200px'"
      @lConfirm="CloseLoadingDialog" :hideFooter="true" :isShowBtn="true">
      <div class="status-tips">
        <div v-if="lastPayway">
          请提醒游客使用 <span>{{ lastPayway }}</span> 支付·
        </div>
        <el-input v-if="lastPayway && lastPayway != '现金'" class="barcode-input" v-model="barCodeInput"
          ref="barCodeInputRef" @keyup.enter="barCodeInfo" placeholder="请将光标聚焦在输入框内扫码收款"></el-input>
      </div>
    </StatusDialog>
    <StatusDialog ref="succesDialog" :type="2" title="收费成功" @lConfirm="CloseStatusDialog" @rConfirm="onEntryPassBtn"
      :confirmText="confirmText" :isShowBtn="true">
    </StatusDialog>
    <StatusDialog ref="errorDialog" :type="3" :title="errorTitle" cancelText="编辑订单" confirmText="重新发起"
      @lConfirm="CloseErrorDialog" @rConfirm="confirm" :isShowBtn="true">
    </StatusDialog>
    <FaceDialog ref="faceDialogRef" @submit="handleCamera"></FaceDialog>

    <!-- 录入通行证 -->
    <EntryPass ref="entryPassRef" @determine="determine"></EntryPass>

    <!-- 购物车 -->
    <shoppingCart :isShowShopCart="isShowShopCart" :badgeVal="badgeVal" @onClickCart="onClickCart"></shoppingCart>

    <!-- 购物车明细 -->
    <shopCartDetails ref="shopCartRef" @onSettlement="onSettlement" @onRetrieve="getShopCartList"></shopCartDetails>

    <!-- 购物车组合支付 -->
		<shopCartSettlement ref="shopCartSettle" @obtainShopData="obtainShopData">
    </shopCartSettlement>
    
    <!-- 打印 -->
		<PrintInfo ref="printInfoRef"></PrintInfo>
  </div>
</template>
<script setup>
  import { ref, onMounted, nextTick } from "vue";
  import { ElMessage } from "element-plus";
  import { BasicApi, ScenicApi, SaleApi,OrderApi, } from "@/plugins/api.js";
  import { Close } from "@element-plus/icons-vue";
  import imgUpload from "@/components/img-upload/img-upload.vue";
  import StatusDialog from "@/components/status-dialog/status-dialog.vue";
  import FaceDialog from "../components/FaceDialog.vue";
  import EntryPass from "../components/EntryPass.vue";
  import { useStore } from "vuex";
  import { checkStr } from "@/utils/common.js";
  import { useRoute } from "vue-router";
  import dayjs from "dayjs";
  import KeyBoard from "@/components/key-board/keyboard.vue";
  import shoppingCart from "@/components/shopping-cart/shoppingCart.vue";
  import shopCartDetails from "../components/shopCartDetails.vue";
	import shopCartSettlement from "../components/shopCartSettlement.vue";
	import PrintInfo from "@/components/print-info/print.vue";

  const store = useStore();
  const route = useRoute();
  const memberInfo = ref(null);  // 注册会员内容
  const allPerson = ref({ m_mobile: '', m_realname: '' });
  const confirmText = ref('录入通行证');
  /** 筛选条件列表 */
  const filters = ref([
    {
      filterType: "select",
      name: "s_id",
      value: "",
      placeholder: "请选择园区",
      options: [],
      val: "s_id",
      lab: "s_name",
    },
    {
      filterType: "select",
      name: "stt_id",
      value: "",
      placeholder: "请选择门票类型",
      options: [],
      val: "stt_id",
      lab: "stt_name",
      action: "typeChange",
    },
    {
      filterType: "select",
      name: "st_name",
      value: "",
      placeholder: "请选择资源名称",
      options: [],
      val: "name",
      lab: "name",
    },
  ]);
  const searchMembers = ref([]);  // 搜索会员信息结果
  const searchContent = ref('');  // 搜索会员信息
  const memberEvent = () => {
    noData.value = false;
    searchMembers.value = [];
    if (!searchContent.value) {
      return false;
    };
    BasicApi.getMemberTicket({ keywords: searchContent.value }).then((res) => {
      if (res.Code === 200) {
        noData.value = true;
        searchMembers.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取会员信息失败！";
        ElMessage.error(msg);
      }
    });
  }

  const showUpload = ref(false)
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "st_name",
      label: "门票名称",
      minWidth: 150,
      color: "--text-color",
    },
    {
      prop: "type_name",
      label: "门票类型",
      minWidth: 80,
      color: "--text-color",
    },
    {
      prop: "user_info",
      label: "使用期限",
      minWidth: 200,
      color: "--text-third-color",
    },
    {
      prop: "st_price",
      label: "门票售价",
      minWidth: 100,
      color: "--theme-color",
    },
    {
      prop: "all_stock_new",
      label: "库存",
      minWidth: 100,
      color: "--text-third-color",
    },
    {
      prop: "today_buyed_stock",
      label: "今日已售",
      minWidth: 80,
      color: "--warning-color",
    },
    {
      prop: "buyed_stock",
      label: "已售",
      minWidth: 80,
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 100,
      bottons: [
        {
          name: "售票",
          action: "saleTickets",
          token: "n_Y07Z8iMwoVoiQWYmLLnHnVNgMf0Y",
          className: "theme-font-btn",
        },
        // {
        //   name: "团体售票",
        //   action: "groupSaleTickets",
        //   token: "n_Y07Z8iMwoVoiQWYmLLnHnVNgMf0Y",
        //   className: "black-font-btn",
        //   HiddenKey: "st_sttid",
        //   showValue: [1, 3],
        // },
      ],
    },
  ]);
  /** 餐厅表格数据 */
  const ticketTable = ref(null);
  // 获取园区列表数据
  const getScenicByProject = () => {
    BasicApi.getScenicByProject({}).then((res) => {
      if (res.Code === 200) {
        filters.value[0].options = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取园区数据失败！";
        ElMessage.error(msg);
      }
    });
  }
  /** 获取门票类型 */
  const getTicketTypes = () => {
    ScenicApi.getNewTicketType().then((res) => {
      if (res.Code === 200) {
        filters.value[1].options = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取门票类型数据失败！";
        ElMessage.error(msg);
      }
    });
  };
  // const ticketCode = ref(false);
  /** 新增/编辑弹框 */
  const ticketDialog = ref(null);
  // 识别身份
  const connectAddress = ref("127.0.0.1:30004");
  // function cleanMsg(index) {
  //   if (visitorList.value && visitorList.value.length) {
  //     visitorList.value[index].m_realname = "";
  //     visitorList.value[index].m_idcard = "";
  //   }
  // }
  function hex2a(hex) {
    let str_list = "";
    for (let i = 0; i < hex.length && hex.substr(i, 2) !== "00"; i += 2) {
      const a = hex.charCodeAt(i);
      const b = hex.charCodeAt(i + 1);
      const c = b * 256 + a;
      str_list += String.fromCharCode(c);
    }
    return str_list.toString();
  }
  const ws = ref(null);
  function getIdentify() {
    const webUrl = "ws://" + connectAddress.value + "/ws";
    ws.value = new WebSocket(webUrl);

    // ws.value.onopen = function () {
    // let szhelp =
    //   "websocket连接成功，url[" +
    //   webUrl +
    //   "]，读卡器上放置身份证后websocket会自动接收身份证数据，如需手动操作请调用WS_ReadInfo()函数\r\n\r\n";
    // szhelp += "支持被动接收和主动请求两种方式\r\n";
    // szhelp +=
    //   "被动接收:当读卡器刷卡成功后会推送身份证信息到websocket，websocket直接显示即可\r\n";
    // szhelp +=
    //   "主动请求:支持网页端主动向服务器请求对应的消息。可查看<WS_ReadInfo><WS_GetASN><WS_GetBCardNo>这三个接口";
    // console.log(szhelp);
    // };
    ws.value.onclose = function () {
      // console.log("websocket已断开");
    };
    ws.value.onmessage = function (messageEvent) {
      const jsonobject = JSON.parse(messageEvent.data);
      if (jsonobject.Ret == 0) {
        if (jsonobject.Cmd == 10001) {
          // cleanMsg(index);
          // console.log("websocket 协议 读取身份证成功");
          const szparam = JSON.parse(window.atob(jsonobject.UserParam));

          const name = hex2a(window.atob(szparam.CardInfo.Name));
          // const m_realname = name.replace(/ /g, "");
          // const m_idcard = hex2a(window.atob(szparam.CardInfo.No));

          if (
            document.getElementById(nameRef.value + nameIndex.value) ==
            document.activeElement
          ) {
            // visitorList.value[nameIndex.value].m_realname = m_realname;
            // visitorList.value[nameIndex.value].m_idcard = m_idcard;

            // if (ticketCode.value) {
            //   if (!visitorList.value[nameIndex.value]["paper_sn"]) {
            //     const visitorSnRef = document.getElementById(
            //       "visitorSn" + nameIndex.value
            //     );
            //     visitorSnRef.focus();
            //   } else {
            //     const visitorNameRef = document.getElementById(
            //       nameRef.value + nextIndex.value
            //     );
            //     if (visitorNameRef) {
            //       visitorNameRef.focus();
            //     }
            //   }
            // }

            // if (!ticketCode.value) {
            //   // 周期票不用跳到下一个 要上传人脸
            //   if ([1, 3].includes(ticketInfo.value.st_sttid)) {
            //     const visitorNameRef = document.getElementById(
            //       nameRef.value + nextIndex.value
            //     );
            //     if (visitorNameRef) {
            //       visitorNameRef.focus();
            //     }
            //   }
            // }
          }
        }
      } else if (jsonobject.Cmd == 30401) {
        const szparam = JSON.parse(window.atob(jsonobject.UserParam));
        // console.log("websocket 协议 读取A卡SN成功：" + szparam.SN);
      } else if (jsonobject.Cmd == 20401) {
        const szparam = JSON.parse(window.atob(jsonobject.UserParam));
        // console.log("websocket 协议 读取身份证卡片SN成功：" + szparam.SN);
      } else if (jsonobject.Cmd == 20511) {
        const szparam = JSON.parse(window.atob(jsonobject.UserParam));
        // console.log("websocket 协议 读卡器唯一号：" + szparam.SN);
      } else if (jsonobject.Cmd == 1000) {
        const szparam = JSON.parse(window.atob(jsonobject.UserParam));
        if (szparam.State == 0) {
          // console.log("读卡器已被拔出");
        } else {
          // console.log("读卡器已插入");
        }
      } else {
        // console.log("websocket 协议调用失败，原因：" + jsonobject.ErrInfo);
      }
    };
  }
  const nameIndex = ref(null);
  const nextIndex = ref(null);
  const nameRef = ref("");
  // function handleSn(data, index, index2) {
  //   // const visitorSnRef = document.getElementById(data + index); // 当前名字框
  //   // const nextVisitorSnRef = document.getElementById(data + index2); // 下一个名字框
  //   if (data == 'children') {
  //     // 儿童
  //     if (index2 == childrenInfo.value.length) {
  //       // 如果当前光标在儿童最后一行，则光标跳成人第一行
  //       document.getElementById('adult0').focus();
  //     } else {
  //       document.getElementById(data + index2).focus();
  //     }
  //   } else {
  //     // 成人
  //     if (index2 !== adultInfo.value.length) {
  //       document.getElementById(data + index2).focus();
  //     }
  //   }
  // }
  // function handleFocus(data, index, index2) {
  //   // 聚焦状态
  //   nameRef.value = data;
  //   nameIndex.value = index;
  //   nextIndex.value = index2;
  // }
  // 表单
  const formRef = ref(null);
  const commonFormRules = {
    num: [
      {
        required: true,
        message: "请输入售票数量",
        trigger: "blur",
      },
    ],
    is_register: [{ required: true, message: '请选择是否激活', trigger: 'change' }],
    start_time: [
      {
        required: true,
        message: "请选择日期",
        trigger: "blur",
      },
    ],
    // payway: [
    //   {
    //     required: true,
    //     message: "请选择支付方式",
    //     trigger: "change",
    //   },
    // ],
  };
  const formRules = ref();
  const formData = ref({
    num: "1",
    payway: '',
    start_time: dayjs().format("YYYY-MM-DD"),
    yh_money: '',
    coupon_money: '',
    ar_id: "",
    // ar_tourist: "",
    // ar_remark: "",
    m_id: '',
    mobile: '',
    is_register: '',  // 是否激活
  });
  const loadingDialog = ref(null);
  const succesDialog = ref(null);
  const errorDialog = ref(null);
  const barCodeInputRef = ref(null);
  const barCodeInput = ref("");
  const errorTitle = ref("订单支付失败");
  const lastOrderSn = ref("");

  function barCodeInfo() {
    const lastParmas = {
      order_sn: lastOrderSn.value,
      auth_code: barCodeInput.value,
    };
    barCodeInput.value &&
      ScenicApi.barCodeInfo(lastParmas).then((res) => {
        if (res.Code == 200) {
          if (res.Data.Code == 200) {
            confirmText.value = formData.value.is_register == '1' ? '确定' : '录入通行证';
            barCodeInput.value = "";
            succesDialog.value.show();
            loadingDialog.value.close();
            lodopSn();
          } else if (res.Data.Code == 205) {
            setTimeout(() => {
              barCodeInfo();
            }, 3000);
          } else {
            barCodeInput.value = "";
            errorTitle.value = res.Data.Message || "订单支付失败";
            loadingDialog.value.close();
            errorDialog.value.show();
            lastOrderSn.value = "";
          }
        } else {
          loadingDialog.value.close();
          errorDialog.value.show();
          lastOrderSn.value = "";
        }
      });
  }
  const lastPayway = ref(null);
  function getOrderInfo(orderSnParmas) {
    ScenicApi.orderInfo(orderSnParmas).then((res) => {
      if (res.Code == 200) {
        lastPayway.value = res.Data.payway_name;
        lastOrderSn.value = res.Data.or_sn;
        if (res.Data.or_pay_status == 2) {
          //支付成功
          // succesDialog.value.show();
          entryPassRef.value.openDialog(res.Data.or_sn, 'ticket');
          loadingDialog.value.close();
          ticketDialog.value.close();
          lodopSn();
        } else if (res.Data.or_pay_status == 1) {
          nextTick(() => {
            barCodeInputRef.value.focus();
          });
          // timer.value = window.setInterval(() => {
          //   setTimeout(() => {
          //     barCodeInfo({ order_sn: res.Data.or_sn });
          //   }, 0);
          // }, 3000);
        }
      }
    });
  }
  //打印
	const printInfoRef = ref(null)
	const lodopSn = () => {
		OrderApi.printOrder({
			sn: lastOrderSn.value
		}).then(res => {
			if (res.Code == 200) {
				printInfoRef.value.setDataAndPrint(res.Data);
			}
		})
	}
  /**
   * 
   * 录入通行证确定按钮
   * 
   * */
  const determine = (data) => {
    entryPassRef.value.dialogRef.isLoading = true;
    ScenicApi.cardReceive(data).then((res) => {
      entryPassRef.value.dialogRef.isLoading = false;
      if (res.Code === 200) {
        ElMessage.success(`录入成功！`);
        entryPassRef.value.automaticSwitching();
        // CloseStatusDialog();
        ticketTable.value.tableLoad();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  function CloseErrorDialog() {
    barCodeInput.value = "";
    lastOrderSn.value = "";

    errorDialog.value.close();
  }
  function CloseLoadingDialog() {
    lastOrderSn.value = "";

    barCodeInput.value = "";
    loadingDialog.value.close();
  }
  // function objectValueAllEmpty(object) {
  //   let isEmpty = true;
  //   Object.keys(object).forEach(function (x) {
  //     if (object[x] != null && object[x] != "") {
  //       isEmpty = false;
  //     }
  //   });
  //   if (isEmpty) {
  //     //值全为空
  //     return true;
  //   }
  //   return false;
  // }
  // 关闭售票对话框
  const wCancel = () => {
    showUpload.value = false;
  }
  // 确定售票按钮
  function confirm() {
    if (formRef.value) {
      formRef.value.validate((valid) => {
        if (valid) {
          lastPayway.value = null;
          lastOrderSn.value = '';
          if (formData.value.m_id == '') {
            ElMessage.error("请选择会员！");
            return false;
          }
          if (formData.value.payway == '') {
            ElMessage.error("请选择付款方式！");
            return false;
          }
          const parmas = {
            stid: ticketInfo.value.st_id,
            sid: ticketInfo.value.st_sid,
            start_time: formData.value.start_time,
            payway: formData.value.payway,
            m_id: formData.value.m_id,
            mobile: formData.value.mobile,
            num: formData.value.num,
            // buy_user: buy_user,
            child_img: [],
            receive_record_audit: [],
            receive_record_child: [],
            child_birthday: [],  // 生日信息
            is_register: formData.value.is_register, // 是否激活
          };
          // 如果需要上传人脸
          // if (ticketInfo.value.img_count_data.need_img_num > 0) {
          //   // 判断上传照片张数,人脸数必须等于儿童数
          //   if (facialPhotos.value.length !== ticketInfo.value.img_count_data.need_img_num) {
          //     ElMessage.error(`请录入${ticketInfo.value.img_count_data.need_img_num}张人脸`);
          //     return false;
          //   }
          // }
          // 处理生日信息
          if (birthdayCount.value.length > 0) {
            for (var i = 0; i < birthdayCount.value.length; i++) {
              if (!birthdayCount.value[i].child_birthday) {
                ElMessage.error("请选择生日日期！");
                return false;
              }
              parmas.child_birthday.push(birthdayCount.value[i].child_birthday);
            }
          }
          // 处理上传人脸
          if (formData.value.is_register == '2' && facialPhotos.value.length > 0) {
            facialPhotos.value.forEach(item => {
              parmas.child_img.push(item.key);
            })
          }
          if (isShowRight) {
            // 处理儿童信息
            childrenInfo.value.forEach(item => {
              if (item.paper_sn !== '') {
                parmas.receive_record_child.push(item.paper_sn);
              }
            })
            // 处理成人信息
            adultInfo.value.forEach(item => {
              if (item.paper_sn !== '') {
                parmas.receive_record_audit.push(item.paper_sn);
              }
            })
          }
          ticketType.value == 2
            ? (parmas["yh_money"] = formData.value.yh_money)
            : (parmas["coupon_money"] = formData.value.coupon_money);
          // if (ticketInfo.value.st_sttid != 2) {
          //   parmas["st_use_stime"] = formData.value.timeArr[0];
          //   parmas["st_use_etime"] = formData.value.timeArr[1];
          // }
          // if (formData.value.payway == 7) {
          //   parmas["ar_id"] = formData.value.ar_id;
          //   parmas["ar_tourist"] = formData.value.ar_tourist;
          //   parmas["ar_remark"] = formData.value.ar_remark;
          // }
          ticketDialog.value.isLoading = true;
          if (ticketType.value == 1) {
            ScenicApi.sellTicket(parmas).then((res) => {
              ticketDialog.value.isLoading = false;
              if (res.Code === 200) {
                const orderSnParmas = {
                  order_sn: res.Data.or_sn,
                };
                loadingDialog.value.show();
                setTimeout(() => {
                  getOrderInfo(orderSnParmas);
                }, 2000);
              } else {
                let msg = res.Message ? res.Message : `售票失败！`;
                ElMessage.error(msg);
              }
            });
          } else {
            ScenicApi.sellGroupTicket(parmas).then((res) => {
              ticketDialog.value.isLoading = false;
              if (res.Code === 200) {
                const orderSnParmas = {
                  order_sn: res.Data.or_sn,
                };
                loadingDialog.value.show();
                setTimeout(() => {
                  getOrderInfo(orderSnParmas);
                }, 2000);
              } else {
                let msg = res.Message ? res.Message : `售票失败！`;
                ElMessage.error(msg);
              }
            });
          }
        }
      });
    }
  }
  const entryPassRef = ref(null);  // 录入通行证对象
  /**
   * 
   * 支付成功后点击录入通行证/确定按钮
   * 
   * */
  const onEntryPassBtn = () => {
    // 不激活
    if (formData.value.is_register == '1') {
      CloseStatusDialog();
      ticketTable.value.tableLoad();
    } else {
      // 激活
      entryPassRef.value.openDialog(lastOrderSn.value, 'ticket');
    }
  }

  function CloseStatusDialog() {
    lastOrderSn.value = "";

    barCodeInput.value = "";
    errorDialog.value.close();
    succesDialog.value.close();
    ticketDialog.value.close();
    ticketTable.value.tableLoad();
  }
  // const visitorList = ref([]);
  // 是否显示儿童信息录入
  const isShowRight = ref(true);
  // 选择初次使用日期
  const startTimeChange = () => {
    isShowRight.value = formData.value.start_time < Date.now();
    // 清空填入信息
    childrenInfo.value = [];
    adultInfo.value = [];
    if (ticketInfo.value.st_child_ticket_count >= 0) {
      for (var i = 0; i < +ticketInfo.value.st_child_ticket_count; i++) {
        const arr = JSON.parse(JSON.stringify(informationObj.value));
        childrenInfo.value.push(arr);
      }
    }
    if (ticketInfo.value.st_audit_ticket_count >= 0) {
      for (var i = 0; i < +ticketInfo.value.st_audit_ticket_count; i++) {
        const arr = JSON.parse(JSON.stringify(informationObj.value));
        adultInfo.value.push(arr);
      }
    }
  }
  function disabledDate(current) {
    return current.getTime() < Date.now() - 8.64e7;
  }

  const ticketInfo = ref({});
  const ticketType = ref(null);
  const childrenInfo = ref([]);
  const adultInfo = ref([]);
  const facialPhotos = ref([]);
  const srcList = ref([]);
  const yhKeyBoardRef = ref(null);  // 软键盘对象
  const couponKeyBoardRef = ref(null);  // 抵扣金额对象
  const mkeyBoardRef = ref(null);  // 售票软键盘对象
  const birthdayCount = ref([]);  // 生日日期
  const numKeyBoardRef = ref(null);  // 售票数量软键盘对象
  const numShowKey = () => {
    if (ticketType.value == '1') {
      couponKeyBoardRef.value.closeKey();
    } else if (ticketType.value == '2') {
      yhKeyBoardRef.value.closeKey();
    }
    mkeyBoardRef.value.closeKey();
  }
  /**
   * 
   * 优惠金额关闭其他软键盘
   * 
   * **/
  const yhShowKey = () => {
    if (ticketType.value == '1') {
      couponKeyBoardRef.value.closeKey();
    }
    mkeyBoardRef.value.closeKey();
    numKeyBoardRef.value.closeKey();
  }
  /**
   * 
   * 代金券抵扣金额关闭其他软键盘
   * 
   * **/
  const couponShowKey = () => {
    if (ticketType.value == '2') {
      yhKeyBoardRef.value.closeKey();
    }
    mkeyBoardRef.value.closeKey();
    numKeyBoardRef.value.closeKey();
  }
  /**
   * 
   * 会员信息关闭其他软键盘
   * 
   * **/
  const searchShowKey = () => {
    if (ticketType.value == '1') {
      couponKeyBoardRef.value.closeKey();
    } else if (ticketType.value == '2') {
      yhKeyBoardRef.value.closeKey();
    }
    numKeyBoardRef.value.closeKey();
  }
  const informationObj = ref({
    m_realname: "",
    paper_sn: '',
  });
  function getTicketInfo(row) {
    birthdayCount.value = [];
    store.dispatch("getQiniuData");
    const yhMoneyRule =
      ticketType.value == 2
        ? {
          yh_money: [
            {
              required: true,
              message: "请输入优惠金额",
              trigger: "blur",
            },
          ],
        }
        : {
          coupon_money: [
            {
              required: true,
              message: "请输入代金券抵扣金额",
              trigger: "blur",
            },
          ],
        };

    ScenicApi.ticketInfo({ st_id: row.st_id }).then((res) => {
      if (res.Code === 200) {
        ticketInfo.value = res.Data;
        if (res.Data.birthday_count > 0) {
          for (var i = 0; i < res.Data.birthday_count; i++) {
            birthdayCount.value.push({ child_birthday: '' });
          }
        }
        childrenInfo.value = [];
        adultInfo.value = [];
        formData.value = {
          num: "1",
          yh_money: '',
          coupon_money: '',
          payway: '',
          ar_id: "",
          // ar_tourist: "",
          // ar_remark: "",
          start_time: dayjs().format("YYYY-MM-DD"),
          m_id: '',
          mobile: '',
          is_register: '',
        };
        allPerson.value = [];
        srcList.value = [];
        facialPhotos.value = [];
        showUpload.value = res.Data.img_count_data.need_img_num > 0;
        if (ticketInfo.value.st_child_ticket_count >= 0) {
          for (var i = 0; i < +ticketInfo.value.st_child_ticket_count; i++) {
            const arr = JSON.parse(JSON.stringify(informationObj.value));
            childrenInfo.value.push(arr);
          }
        }
        if (ticketInfo.value.st_audit_ticket_count >= 0) {
          for (var i = 0; i < +ticketInfo.value.st_audit_ticket_count; i++) {
            const arr = JSON.parse(JSON.stringify(informationObj.value));
            adultInfo.value.push(arr);
          }
        }
        if (ticketInfo.value.st_sttid == 2) {
          formRules.value = {
            ...commonFormRules,
            ...yhMoneyRule,
          };
          // formData.value["timeArr"] = [
          //   dayjs().format("YYYY-MM-DD"),
          //   dayjs()
          //     .add(+ticketInfo.value.st_use_days - 1, "day")
          //     .format("YYYY-MM-DD"),
          // ];
        } else {
          // const currentDay = dayjs().unix();
          // const beginDay = dayjs(ticketInfo.value.st_use_stime).unix();

          // if (currentDay < beginDay) {
          //   formData.value["timeArr"] = [
          //     dayjs(ticketInfo.value.st_use_stime).format("YYYY-MM-DD"),
          //     dayjs(ticketInfo.value.st_use_stime).format("YYYY-MM-DD"),
          //   ];
          // } else {
          //   formData.value["timeArr"] = [
          //     dayjs().format("YYYY-MM-DD"),
          //     dayjs().format("YYYY-MM-DD"),
          //   ];
          // }
          formRules.value = {
            ...commonFormRules,
            ...yhMoneyRule,
            // timeArr: [
            //   {
            //     required: true,
            //     message: "请输入使用期限",
            //     trigger: "blur",
            //   },
            // ],
          };
        }
        if (ws.value) {
          ws.value.close();
        }
        nameIndex.value = null;
        nameRef.value = "";
        nextIndex.value = null;
        // ticketCode.value = false;
        memberInfo.value = "";
        searchContent.value = '';
        isShowRight.value = true;

        ticketDialog.value.show();
        nextTick(() => {
          numKeyBoardRef.value.clickKey('1');
          })
        setTimeout(() => {
          formRef.value.resetFields();
        }, 200);
        getIdentify();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  // const groupSaleTickets = (row) => {
  //   ticketType.value = 2;
  //   setTimeout(() => {
  //     getTicketInfo(row);
  //   }, 200);
  // };
  const currentRow = ref({});  // 当前售票数据
  const saleTickets = (row) => {
    ticketType.value = 1;

    currentRow.value = row;
    setTimeout(() => {
      getTicketInfo(row);
    }, 200);
  };
  function roundFractional(x, n) {
    return Math.round(x * Math.pow(10, n)) / Math.pow(10, n);
  }
  function totalMoney() {
    let result = null;
    const price = ticketInfo.value.st_price || 0;
    const number = formData.value.num || 1;
    const yhMoney = formData.value.yh_money || 0;
    const coupon_money = formData.value.coupon_money || 0;

    if (ticketType.value == 1) {
      result = price * number - coupon_money;
      if (result < 0) {
        formData.value.coupon_money = 0;
        result = price * number;
      }
    } else {
      result = price * number - yhMoney;
      if (result < 0) {
        formData.value.yh_money = 0;
        result = price * number;
      }
    }

    return roundFractional(result, 2);
  }

  const faceLoading = ref(false);
  function uploadLoading(flag) {
    faceLoading.value = flag;
  }

  /** 图片上传 */
  const uploadFile = (obj) => {
    faceLoading.value = false;
    srcList.value.push(obj.standard_url);
    facialPhotos.value.push(obj);
  };
  /** 图片删除 */
  const deleteFile = (obj, i) => {
    facialPhotos.value.splice(i, 1);
    srcList.value.splice(i, 1);
  };
  // 支付方式选项
  const orderTypeOptions = ref([]);
  // 获取支付方式
  const getOrderType = () => {
    ScenicApi.getOrderType().then((res) => {
      if (res.Code === 200) {
        orderTypeOptions.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取支付方式失败！";
        ElMessage.error(msg);
      }
    });
  };
  const companyOptions = ref([]);
  function changeRadio(v) {
    if (v == 7) {
      ScenicApi.accountOptions().then((res) => {
        if (res.Code === 200) {
          companyOptions.value = res.Data;
        } else {
          let msg = res.Message ? res.Message : "获取挂账公司数据失败！";
          ElMessage.error(msg);
        }
      });
    }
  }
  const faceDialogRef = ref(null);
  const currentCameraIndex = ref(null);
  function openFaceDialog(index) {
    currentCameraIndex.value = index;
    faceDialogRef.value.openDialog();

  }
  function handleCamera(data) {
    // visitorList.value[currentCameraIndex.value].img = [
    //   { file_url: data.standard_url },
    // ];
    // visitorList.value[currentCameraIndex.value].file_key = data.key;
    srcList.value.push(data.standard_url);
    facialPhotos.value.push(data);
    faceDialogRef.value.closeDialog();
  }
  const noData = ref(false);
  /**
  *
  * 搜索计划负责
  *
  * */
  // const querySearch = (queryString, cb) => {
  //   noData.value = false;
  //   if (!queryString) {
  //     cb([]);
  //     return false;
  //   };
  //   BasicApi.getMemberTicket({ keywords: queryString }).then((res) => {
  //     if (res.Code === 200) {
  //       let defaultDatas = [];
  //       noData.value = true;
  //       if (res.Data) {
  //         cb(res.Data);
  //       } else {
  //         defaultDatas = [{ default: '无会员' }]
  //         cb(defaultDatas);
  //       };
  //     } else {
  //       let msg = res.Message ? res.Message : "获取会员信息失败！";
  //       ElMessage.error(msg);
  //     }
  //   });
  // }
  /**
  *
  * 选择搜索会员
  *
  * */
  // const autocomplete = ref(null);
  const handleSelect = (item) => {
    memberInfo.value = '';
    searchContent.value = '';
    allPerson.value = item;
    formData.value.m_id = item.m_id;
    // autocomplete.value.close();
    searchMembers.value = [];
    mkeyBoardRef.value.clearContent();
    mkeyBoardRef.value.handleClickOutside();
  }
  /**
   * 
   * 注册会员
   * 
   * */
  const onRegisterBtn = () => {
    if (!checkStr(searchContent.value, "phone")) {
      ElMessage.error(
        `注册会员手机号格式错误！`
      );
      return false;
    }
    ScenicApi.memberAddition({ m_mobile: searchContent.value }).then((res) => {
      if (res.Code === 200) {
        handleSelect(res.Data);
      } else {
        let msg = res.Message ? res.Message : "会员注册失败！";
        ElMessage.error(msg);
      }
    });
  }
  const voucherDeduction = (val) => {
    formData.value.coupon_money = val;
  }

  /**
   * 
   * 收银-购物车
   * 
   * **/
  const getCacheDatas = () => {
    // if (sessionStorage.getItem("shopCart")) {
    //   shopCart.value = JSON.parse(sessionStorage.getItem("shopCart"));
    // }
    // if (shopCart.value.length > 0) {
    //   shopDialog.value.show();
    //   nextTick(() => {
    //     shopTable.value.tableLoad();
    //   })
    // }
  }
  const isShowShopCart = ref(false);  // 是否显示购物车
  const badgeVal = ref(0);  // 徽章
  const cartDatas = ref([]);  // 购物车数据
  const shopCartRef = ref(null);
  const total_money = ref(0);
  /**
   * 
   * 添加至购物车按钮
   * 
   * **/
  const onIncreaseBtn = () => {
    if (formRef.value) {
      formRef.value.validate((valid) => {
        if (valid) {
          if (formData.value.m_id == '') {
            ElMessage.error("请选择会员！");
            return false;
          }
          // console.log("添加", formData.value)
          let parameter = {
            ticket_data: [],
            type: 1,
            goods_data: [],
          };
          const parmas = {
            stid: ticketInfo.value.st_id,
            sid: ticketInfo.value.st_sid,
            start_time: formData.value.start_time,
            payway: '',
            m_id: formData.value.m_id,
            mobile: formData.value.mobile,
            num: formData.value.num,
            child_img: [],
            receive_record_audit: [],
            receive_record_child: [],
            child_birthday: [],  // 生日信息
            is_register: formData.value.is_register, // 是否激活
          };
          // 处理生日信息
          if (birthdayCount.value.length > 0) {
            for (var i = 0; i < birthdayCount.value.length; i++) {
              if (!birthdayCount.value[i].child_birthday) {
                ElMessage.error("请选择生日日期！");
                return false;
              }
              parmas.child_birthday.push(birthdayCount.value[i].child_birthday);
            }
          }
          // 处理上传人脸
          if (formData.value.is_register == '2' && facialPhotos.value.length > 0) {
            facialPhotos.value.forEach(item => {
              parmas.child_img.push(item.key);
            })
          }
          if (isShowRight) {
            // 处理儿童信息
            childrenInfo.value.forEach(item => {
              if (item.paper_sn !== '') {
                parmas.receive_record_child.push(item.paper_sn);
              }
            })
            // 处理成人信息
            adultInfo.value.forEach(item => {
              if (item.paper_sn !== '') {
                parmas.receive_record_audit.push(item.paper_sn);
              }
            })
          }
          ticketType.value == 2
            ? (parmas["yh_money"] = formData.value.yh_money)
            : (parmas["coupon_money"] = formData.value.coupon_money);
          parameter.ticket_data.push(parmas);
          // console.log("params", parmas, parameter);
          SaleApi.addShopCart(parameter).then(res => {
            if (res.Code == 200) {
              ElMessage.success("购物车添加成功");
              ticketDialog.value.close();
              // setTimeout(() => {
              //   getTicketInfo(currentRow.value);
              // }, 200);
            } else {
              ElMessage.error(res.Message);
            }
            getShopCartList();
          })
        }
      })
    }
  }
	/**
	 * 
	 * 点击购物车按钮
	 * 
	 * **/
  const onShoppingBtn = () => {
    let params = {
      ticket_data: [],
      type: 1,
      goods_data: [],
    };
    cartList.value.forEach(item => {
      params.goods_data.push({
        goods_id: item.goods_id,
        buy_no: item.buy_no,
      });
    })
    // console.log("params", params);
    SaleApi.addShopCart(params).then(res => {
      if (res.Code == 200) {
        cartList.value = [];
      } else {
        ElMessage.error(res.Message);
      }
      getShopCartList();
    })
  }
	/**
	 * 
	 * 获取购物车数据
	 * 
	 * **/
  const getShopCartList = () => {
    SaleApi.getShopCartList().then(res => {
      if (res.Code == 200) {
        badgeVal.value = res.Data.list ? res.Data.list.length : 0;
        total_money.value = res.Data.total_money;
        isShowShopCart.value = badgeVal.value > 0;
      } else {
        ElMessage.error(res.Message);
      }
    })
  }
	/**
	 * 
	 * 点击购物车图标
	 * 
	 * **/
  const onClickCart = () => {
    shopCartRef.value.openDialog();
    // nextTick(()=>{
    // 	shopCartRef.value.openDialog();
    // })
    // sessionStorage.setItem("isShopping", true);
    // router.push("/scenic/sale/olsale");
  }
  const shopCartSettle = ref(null);
	/**
	 * 
	 * 结算
	 * 
	 * **/
  const onSettlement = (val) => {
    shopCartSettle.value.openDialog(val);
  }
	/**
	 * 
	 * 下单成功
	 * 
	 * **/
	const obtainShopData = (type) => {
		if (type == '1') {
			// 关闭购物车明细弹框
			shopCartRef.value.closeDialog();
			ticketTable.value.tableLoad();
		} else {
			// 获取购物车数据
			getShopCartList();
		}
	}
  /**
   * 
   * 选择会员卡类型获取二级分类
   * 
   * */
  const typeChange = (item) => {
    filters.value[2].value = '';
    ticketTable.value.tableLoad();
    if (item.value == '') {
      filters.value[2].options = [];
      return false;
    }
    ScenicApi.getSourceNameByType({ source: '1', type: item.value }).then((res) => {
      if (res.Code === 200) {
        filters.value[2].options = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  }




  onMounted(() => {
    getScenicByProject();
    getTicketTypes();
    getOrderType();
    ticketTable.value.tableLoad();
    // entryPassRef.value.openDialog();
    getShopCartList();
  });
</script>

<style lang="scss">
  .flex {
    display: flex;
  }

  .justify_between {
    justify-content: space-between;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .sx-m-b {
    margin-bottom: 10px;
  }

  .sx-m-t {
    margin-top: 10px;
  }

  .into-face {
    background-color: var(--search-uncheck-bg-color);
    padding: 5px;
    border: 1px solid #33A8FF;
    color: #33A8FF;
    cursor: pointer;
    border-radius: 4px;
  }

  .el-autocomplete-suggestion {
    width: 100%;
  }

  .person_content {
    background-color: #DDF1FF;
    color: #33A8FF;
    margin-top: 10px;
    line-height: 38px;
    padding: 0 10px;
    width: 200px;
  }

  .face-dropdown-menu .face-dropdown-item .img-upload>ul .upload-text {
    color: #606266;
    cursor: pointer;
  }

  .face-dropdown-menu .face-dropdown-item:hover .img-upload>ul .upload-text {
    color: var(--theme-color);
  }

  .face-dropdown-menu .face-dropdown-item {
    padding: 0;
    width: 100px;
    display: flex;
    line-height: 1;
    justify-content: center;
  }

  .face-dropdown-item:nth-of-type(1) {
    .upload-text {
      height: 36px;
      line-height: 36px;
      display: inline-block;
    }
  }

  .face-dropdown-item:last-child {
    line-height: 36px !important;
  }

  .oltiket {
    font-family: "Source Han Sans CN";

    .member-info {
      margin-right: 10px;
      width: 240px;
      border: 1px solid var(--border-grey-color);
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      overflow: hidden;
      max-height: 280px;
      padding: 10px 20px;
      box-sizing: border-box;
      background: var(--select--bg-color);
      z-index: 1;
    }

    .selected-members {
      cursor: pointer;
      line-height: 34px;
      white-space: nowrap;
      text-overflow: ellipsis;
      justify-content: space-between;
      display: flex;
      color: var(--text-color);
    }

    .el-switch__core {
      width: 50px !important;
      height: 20px;
    }

    .content {
      padding: 20px;
    }

    .el-form-item__content {

      .el-date-editor,
      .el-select {
        width: 100%;

        .el-range-input {
          background-color: transparent;
        }
      }
    }

    .el-divider--horizontal {
      margin: 10px 0;
    }


    .ticket-dialog {
      .el-dialog__body {
        padding: 0;
        overflow: hidden;

        .ticket-content {
          min-height: 450px;
          max-height: 450px;
          /* display: flex; */

          .right-content {
            width: 40%;
            border-left: 1px solid #eee;
            overflow-y: auto;
            padding: 20px;

            .el-empty__image {
              width: 100px;
            }

            .right-title {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              margin-bottom: 20px;



              .enter-switch {
                display: flex;
                align-items: center;

                >span {
                  color: var(--text-gray-color);
                  padding-right: 6px;
                }
              }
            }

            .visitor-item {
              border: 2px dashed #eee;
              padding: 10px;
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              position: relative;

              .close-icon {
                position: absolute;
                right: 4px;
                top: 4px;

                >i {
                  font-size: 16px;
                  font-weight: bold;
                  cursor: pointer;

                  &:hover {
                    color: var(--theme-color);
                  }
                }
              }

              .upload-box {
                text-align: right;
                display: flex;
                align-items: center;

                .img-container {
                  .el-dropdown {
                    position: absolute;
                    color: var(--text-color);
                    right: 16px;
                    bottom: 20px;
                    cursor: pointer;
                  }

                  .face-img {
                    position: relative;

                    .el-image {
                      width: 56px !important;
                      height: 56px !important;
                      border-radius: 6px;
                    }

                    .del {
                      position: absolute;
                      top: 0;
                      right: 0;
                      height: 13px;
                      width: 18px;
                      line-height: 13px;
                      color: var(--text-white-color);
                      background-color: rgba(0, 0, 0, 0.5);
                      border-radius: 0 4px 0 4px;
                      text-align: center;
                      font-size: 12px;
                      cursor: pointer;
                    }
                  }
                }
              }

              .visitor-form {
                flex: 1;

                .el-form-item__content {
                  height: 20px;
                  line-height: 20px;
                }

                .el-form-item__label {
                  padding-right: 0;
                  user-select: none;
                }

                .el-input__inner {
                  padding-right: 10px;
                }

                .el-form-item {
                  /* width: 80%; */
                  align-items: center;
                  margin-bottom: 8px;

                  .el-input__inner {
                    height: 20px;
                    line-height: 20px;
                  }

                  .el-form-item__error {
                    top: 68%;
                    left: 15px;
                  }
                }

                .el-form-item__content .el-input__inner,
                .el-form-item__content .el-textarea__inner {
                  background-color: transparent;
                }
              }
            }
          }

          .left-content {
            max-height: 450px;
            flex: 1;
            overflow-y: auto;
            padding: 20px;

            .basic-info {
              display: flex;
              justify-content: space-around;
              width: 100%;
              background-color: var(--theme-bg-color);
              padding: 10px;
              border-radius: 4px;

              .ticket-title {
                color: var(--text-third-color);
                font-weight: 700;
                font-size: 16px;
              }

              .basic-item {
                /* margin-top: 10px; */
                /* width: 100%; */
                color: var(--text-third-color);

                >span {
                  color: var(--text-gray-color);
                }
              }
            }

            .ticket-form {
              margin-top: 20px;

              .total-money {
                color: var(--text-third-color);
                margin-bottom: 10px;

                >span {
                  margin-left: 4px;
                  color: #0072F6;
                  font-weight: bold;
                  font-size: 20px;
                }
              }

              .el-form-item__label {
                color: var(--text-third-color);
                padding-bottom: 4px;
              }
            }
          }
        }
      }

      .el-dialog__footer {
        padding: 10px;
        border-top: 1px solid var(--border-grey-color);
      }
    }

    .barcode-input {
      width: 100%;
      margin-top: 30px;
    }

    .status-tips {
      color: var(--text-third-color);
      margin-top: 20px;

      span {
        color: var(--theme-color);
      }
    }
  }
</style>